import Intercom, { update } from '@intercom/messenger-js-sdk';
import { AuthenticatedUser } from './types';

export default class IntercomTracker {
  constructor(intercomAppId: string) {
    if (typeof Intercom === 'function') {
      Intercom({
        app_id: intercomAppId,
        horizontal_padding: 20,
        vertical_padding: 10,
      });
    } else {
      console.error('Intercom is not initialized. Please check your snippet.');
    }
  }

  userLoggedIn = ({ name, email, tenant, subdomain }: AuthenticatedUser) => {
    update({
      name,
      email,
      company: {
        id: tenant,
        Tenant: tenant,
        'Tenant region': subdomain || '',
      },
    });
  };
}
